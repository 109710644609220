.AllProjects_Container{
    .header{
        background-image: linear-gradient(rgba($color: #2969A7, $alpha: .3), rgba($color: #2969A7, $alpha: .5)),  url('../../assets/images/dream.webp');
        height: 80VH;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        color: white;
        .link, h1{
            margin-left: 50px;
        }
        a{
            color: white;
            margin-right: 5px;
        }
        h1{
            margin-bottom: 80px;
        }
        .link{
            display: flex;
            align-items: center;
            p{
                margin: 0;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .AllProjects_Container{
       .header{
            background-position: center;
            background-attachment:scroll ;
       }
       
    }
}