.ProjectCard_Container{
    overflow: hidden;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around ;
        height: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    background-position: center;
    background-size: cover;
    color: white;
    margin: 20px;
    height: 300px;
    text-align: center;
    cursor: pointer;
    width: 90%;
    p{
        font-weight: 300;
        letter-spacing: .2em;
        max-width: 80%;
        color: black;
    }
    h4{
        line-height: 1.4em;
        color: #294B81;
        max-width: 90%;
        font-size: 1rem;
    }
    button{
        margin-top: 20px;
    }
}



// ----------------- Media Query ------------------- //

@media only screen and (max-width: 821px) {
    .ProjectCard_Container{
    }
    
}
@media only screen and (max-width: 600px) {
    .ProjectCard_Container{
        height: 80vw;
        
    }
    
}
@media only screen and (max-width: 400px) {
    .ProjectCard_Container{
        h4{
            font-size: .8em;
        }
    }
    
}