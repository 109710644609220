.Footer_Container{
    background-color:  #232B5B;
    color: white;
    display: flex;
    padding: 40px 0;
    font-size: 1.2rem;
    justify-content: space-around;
    a{
        text-decoration: none;
        font-size: inherit;
        color: inherit;
    }
    .logo{
        height: 90px;
    }
    .C2{
        display: flex;
        flex-direction: column;
        align-items: space-between;
        text-align: right;
        height: 100%;
        p{
            margin: 5px 0;
        }
        a{
            margin-bottom: 5px;
        }
        div{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 30px -5px 0 -5px;
        }
    }
    .C1{
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        p{
            line-height: 1.8em;
        }
    }
}
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 800px) {
    .Footer_Container{
        flex-direction: column-reverse;
        padding: 20px;
        align-items: flex-start;
        .C2{
            text-align: left;
            
        }
        .logo{
            height: 60px;
            margin: 20px 0;
        }
    }
    
}

