.NavbarItems{
    z-index: 999;
}
.NavbarItems_Scrolled{
    a{
        color: rgba($color: #000000, $alpha: .6);
    }
    a:hover{
        color: #294B81 !important;
    } 
  
}

.Navbar_Container{
    display: flex;
    position: fixed;
    top: 0;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    margin-top: 20px;
    z-index: 999;
    font-size: 1.5rem;
    padding: 10px 0;
    font-weight: 500;
    div{
        display: flex;
    }
    a{
        margin: 0 20px;
        cursor: pointer;
        
    }
 
    
    .Navbar_logo{
        height: 60px;
        width: auto;
        margin: 0;
        padding: 0;
        user-select: none;
    }
    .MenuIcon{
        height: 30px;
        justify-self: flex-end;
        margin-left: calc(100vw - 40px);
    }
}
.Navbar_ScrollSpy{
    color: rgb(21,151,229)

}

.Navbar_Container_Scrolled{ 
    background-color: white;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    z-index: 999;
    margin-top: 0;   
  
}
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 1039px) {
    .Navbar_Container{
        a{
            margin: 15px 10px;
        }
        .Navbar_logo{
        }
    }
    
}
@media only screen and (max-width: 821px) {
    .Navbar_Container{
        a{
            margin: 15px 10px;
            font-size: 1.2rem;
        }
        .Navbar_logo{
        }
    }
    
}
@media only screen and (max-width: 600px) {
    .Navbar_Container_Scrolled{ 
        background-color: transparent;
        color: rgb(21,151,229);
        z-index: 999;
        margin-top: 15px;
    }
 
    .NavbarItems{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        justify-content: center;
        max-height: 70vh;
        right: 18px;
        margin: 0;
        top: 40px;
        border: 1px solid rgba(0,0,0,0.1);
        margin-top: 20px;
        background-color: white;
        color: black;
        a{
            width: 80vw;
            height: 250px;
            display: flex;
            font-size: 1.4rem;
            justify-content: center;
            align-items: center;
        }
    }
    .Navbar_Container{
        justify-content: space-between;
        border: none;
        a, ul{
            margin: 0;
            padding: 0;
        }
        .Navbar_logo{
            padding: 10px;
        }
    }
    
}
@media only screen and (max-width: 330px) {
    
    .Navbar_Container{
        .MenuIcon{
            transform: scale(1.3);
            margin-right: 1 0px;
        }
     }
}    
