.AnimatedDescription_Container{
    margin:100px 0px ;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    overflow: hidden;
    h2{
        width: 270px;
        margin: 0;
        margin-top: -3px;
        text-align: right;
        color: #294B81
    }
    
    .div{
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        border-left: 2px solid rgba($color: #2C88CD, $alpha: 1);
        padding-left: 30px;
        p{
            width: 30vw;
            margin: 0;
            color: rgba($color: #000000, $alpha: .5);
            text-align: left;
            font-size: 18px;
            line-height: 1.5em;
         }
        
    }
}
  // ----------------- Media Query ------------------- //

  @media only screen and (max-width: 615px) {
    .AnimatedDescription_Container{
        flex-direction: column;
        align-items: flex-start;
        padding: 0 30px;
        div{
            padding: 0;
            margin: 0;
            border: none;
            p{
                width: 90%;
            }
        }
        h2{
            text-align: left;
            margin-bottom: 20px;
            max-width: 80vw;
        }
       
    }
    
}