.EditAboutUsPage_Container{
    display: grid;
    grid-template-columns: 70% 30%;
    min-height: 70vh;
    grid-gap: 0;
    .page{
        max-width: 70vw;
        overflow-x: scroll;
    }
    .AboutusPage_Container{
        .header{
            width: calc(70vw - 8px);
        }
        .row1{
            flex-direction: column-reverse;
            width: calc(70vw - 8px);
            .description{
                max-width: 80%;
                padding: 0;
            }
            .img{
                width: 57vw ;
                max-width: 100vw;
                margin: 40px 0;
            }
        }
        .description2{
            width: calc(70vw - 8px);
        }
        .clients_container{
            width: calc(70vw - 90px);
            margin: 0;
            padding: 40px;
        }
    }
    .editIcon{
        z-index: 9999;
        height: 30px;
        position: absolute;
        left: 67vw;
        margin-top: 10px;
        cursor: pointer;
    }
    .backLink{
        left: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        z-index: 9999;
        color: black;
        cursor: pointer;  
        position: absolute;
        padding: 0 10px;
        background-color: white;
    }
    .Green_Button{
        width: 150px;
        margin: 20px 0 50px 0;
    }

   
    .EditingContainer{
        position: fixed;
        padding: 20px 0 40px 0;
        overflow-y: scroll;
        width: 30vw;
        right: 0;
        background-color: #E5E5E5;
        text-align: left;
        height: 100%;

        h1{
            text-align: center;
            font-size: 2rem;
        }
        .EditInfo, .EditHeader{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .EditHeader{
            textarea{
                margin: 10px 0;
            }
        }
        .aboutus{
            textarea{
                height: 300px !important;
            }
            input{
                height: 40px !important;
            }
            .h3{
                margin-left: 20px;
            }
            
        }
        h3{
            margin: 10px 0 0 0;
            align-self: flex-start;
        }
        .InfoInputs{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 90%;
            .subContainer_Info{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                div{
                    display: flex;
                    flex-direction: column;
                }
                input, textarea{
                    padding: 0 5px;
                    border-radius: 6px;
                    border: none;
                }
                input{
                    margin: 15px 0 10px 0;
                    height: 30px;

                }
                textarea{
                    height: 70px;
                }
                label{
                    margin-left: 6px;
                }
            }
        }
        .images_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            .images{
               display: grid;
               grid-template-columns: 50% 50%;
            }
            label{
                align-self: flex-start;
                margin: 5px;
            }
            .image{
                height: 150px;
                width: 180px;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: 20px;
                    width: 20px;
                    margin: 0 5px;
                    cursor: pointer;
                }
            }
        }
        .dreamImage{
            width: 90%;
            margin: 30px 0 20px 0;
        }
        .bussImage{
            width: 200px;
            height: 150px;
            object-fit: contain;
            margin: 30px auto 0 auto;
        }
        .bussSub{
            border: 1px solid rgba($color: #000000, $alpha: .2);
            padding: 10px;
            margin-bottom: 20px;
        }
        .dreamInputImage, .bussInputImage{
            display: flex;
            align-items: center;
            .inputfile {
                width: 5px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                cursor: pointer;
                padding: 20px;
                position: absolute;
                margin-left: 0px;
            }
            .upload{
                height: 30px;
                margin-right: 10px;
            }
            .uploaded{
                width: 80px;
                margin-left: 30px;
            }
        }
        .bussInputImage{
        }
     
        
    }
    .AddClient{
        display: flex;
        flex-direction: column;
        width: 26vw;
        select ,input{
            border: none;
            margin-bottom: 10px;
            height: 40px;
            padding: 0 4px;
        }
        .inputfile{
          padding: 5px  15px!important;
        }
        .flex{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        label{
            margin: 5px 0;
        }
    }
    .imagesToUpload_Container{
        display: grid;
        grid-template-columns: 50% 50%;
        object-fit: contain;
        justify-items: center;
        margin: 10px 0;
        .imagesToUpload{
            width: 90%;
            height: 100px;
        }
    }
   
    .DropDown_Contianer2{
        width: 27vw;
        h3{
            color: white;
            background-color: rgba($color: #2969A7, $alpha: .9);
            padding: 15px 20px;
            border-radius: 5px;
            font-size: 1em;
            margin: 5px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            span{
                margin-left: 10px;
            }
        }
        .clients{
            display: grid;
            grid-template-columns: 30% 30% 30%;
            justify-items: center;
            justify-content: center;
            .images{
                width: 100px;
                height: 100px;
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .delete{
                cursor: pointer;
            }
            p{
                color: #294B81;
                border-radius: 5px;
                width: 100px;
                text-align: center;
                line-height: 1em;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
        }
        span{
            font-size: 1.4em;
        }
    }
}
.backLink{
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    z-index: 9999;
    color: black;
    cursor: pointer;  
    position: absolute;
    padding: 0 10px;
    background-color: white;
}
.editor {
    box-sizing: border-box;
    cursor: text;
    border-radius: 2px;
    background-color: white;
    z-index: 99999;
    border: 1px solid #DEDEDE;
    overflow-y: scroll;
    margin-top: 8px;
    padding: 0 5px;
    height: 450px;
    .border{
        border-right: 1px solid #D7D7D7;
        padding-right: 10px;
    }
    button{
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
    .toolbar{
        display: flex;
        border-bottom: 1px solid #DEDEDE;
        flex-direction: row !important;
        padding: 5px 0;
        a{
            border: none;
            margin-left: auto;
            margin-right: 5px;
            cursor: pointer;
        }

    }
    .ProseMirror{
     height: 200px;
    }
    .ProseMirror:focus{
        outline: none;

    }
    
}