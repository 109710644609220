.App {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.spinner2{
  margin: 30vh auto;
}
.auto{
  margin: auto;
}
a{
  text-decoration: none;
}
h1,h2,h3{
  text-transform: uppercase;
}
h1{
  font-size: 3.2rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.5rem;
}
h4{
  font-size: 1.2rem;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #39379B;
  border-left-color: white;
  border-radius: 50%;
  background: transparent;
  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
}
.menu{
  position: sticky;
  top: 55px;
  align-self: flex-end;
  margin-bottom: -60px;
  height: 30px;
  width: 30px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  background-color: black;
}
.Secondary_Button{
  background-color: rgba($color: #2C88CD, $alpha: .8);
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.Primary_Button{
  margin-top: 25px;
  background-color: rgba($color: #2C88CD, $alpha: .8);
  cursor: pointer;
  border: none;
  color: #DBE4F4;
  padding: 10px 20px;
  border-radius: 5px;
}
.Tertiary_Button{
  background-color: rgba($color: #2C88CD, $alpha: .8);
  border: none;
  color: white;
  font-size: 14px;
  padding: 7px 14px;
  border-radius: 15wpx;
  cursor: pointer;
}
.Delete_Button{
  background-color: rgba($color: #A63632, $alpha: .8);
  border: none;
  color: white;
  font-size: 14px;
  padding: 7px 14px;
  border-radius: 15wpx;
  cursor: pointer;
}
.Green_Button{
  background-color: rgba($color: #6B8E23, $alpha: .8);
  border: none;
  color: white;
  font-size: 14px;
  padding: 7px 14px;
  border-radius: 15wpx;
  cursor: pointer;
}
.menu{
  position: sticky;
  top: 10px;
}
.icons{
  margin: 0 5px;
  color: black !important;
  padding: 9px;
  display: flex;
  align-items: center;
  background-color: white;
  height: 30px;
  border-radius: 5px;
  z-index: 99;
  cursor: pointer;
  img{
      height: 30px;
      padding: 0 3px;
  }
}

@keyframes stretchs2 {
  0% { padding: 15px 80px; }
  80% {  padding: 20px 85px; margin-left: -5px; margin-top: -5px; font-size: 1.1rem; }
  100% {  padding: 15px 80px;  }  
}  
.video_container{
}
.btn-0 {
  display: inline-block;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: .9rem; 
  letter-spacing: .1rem;
  transition: all .6s;
  position: relative;
  overflow: hidden;
  padding: 15px;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2C88CD;
    transition: all .6s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
.btn-1 {
  display: inline-block;
  border-radius: 15px;
  color: #fff;
  font-size: .8rem; 
  transition: all .4s;
  position: relative;
  overflow: hidden;
  padding: 10px;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2C88CD;
    transition: all .4s;
    border-radius: 5px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
.btn-2 {
  display: inline-block;
  border-radius: 15px;
  color: #fff;
  font-size: .8rem; 
  transition: all .4s;
  position: relative;
  overflow: hidden;
  padding: 10px;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:  #A63632;
    transition: all .4s;
    border-radius: 5px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
.btn-3 {
  display: inline-block;
  border-radius: 15px;
  color: #fff;
  font-size: .8rem; 
  transition: all .4s;
  position: relative;
  overflow: hidden;
  padding: 10px;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:  #6B8E23;
    transition: all .4s;
    border-radius: 5px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}

// ----------------- Media Query ------------------- //

@media only screen and (max-width: 800px) {
  .iconsmobile{
    padding: 0 ;  
    height: 30px !important;
    width: 30px !important;
  }
   @keyframes stretchs2 {
      0% { padding: 12px 50px; }
      60% {  padding: 15px 55px; margin-left: -5px; margin-top: -5px; font-size: 1.1rem;  }
      100% {  padding: 12px 50px;  }  
  }  
}
@media only screen and (max-width: 700px) {
  h1{
    font-size: 2rem;
  } 
}
@media only screen and (max-width: 600px) {
  .icons2{
    margin: 0 5px;
    padding: 5px 3px;
    height: 25px;
    img{
        height: 25px;
        padding: 0 3px;
    }
  }
  .btn-0 {
    font-size: .7em;
  }
  .Primary_Button{
    padding: 10px;
  }
  h2{
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 400px) {
  h1{
    font-size: 1.7rem;
  } 
}
