.ProjectPage_Container{
    display: flex;
    justify-content: center;
    margin-top: 140px;
    margin-bottom: 50px;
    align-items: flex-start;
    h1{
        color: #294B81;
        margin: 0;
        font-size: 1.5rem;
    }
    span{
        color: #294B81;
    }
    p, .p{
        padding : 0 !important;
        font-size: 1.1rem;

    }
 
    li{
        margin: 10px;
        font-weight: 300;
    }
    .date{
        color: #4C4C4C;
        font-weight: 400;
    }
    
    .Images_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .rendered_images{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .Images{
            display: flex;
            max-width: 550px;
            width: 43vw;
            overflow-x: scroll;
            overflow-y: hidden;
            .image{
                width: 100px;
                height: 100px;
                object-fit: cover;
                margin-top: 20px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin: 0 20px;
        }
        .first_image{
            max-width: 550px;
            width: 43vw;
            height: 450px;
            object-fit: cover;
            object-position: 50% 50%;
            
        }
    }
    .description{
        width: 43vw;
        max-width: 550px;
        text-align: left;
        margin-left: 20px;
        .read{
            cursor: pointer;
        }
    }
   
}


@media only screen and (max-width: 1100px) {
    .ProjectPage_Container{
        flex-direction: column;
        align-items: center;
        .Images_container{
            .Images{
                max-width: 70vw;
                width: 70vw;
                margin-right: 0;
            }
            .first_image{
                max-width: 90vw;
                width: 90vw;
            }
          
        }
        .description{
            max-width: 90vw;
            margin-left: 0;
            width: 90vw;
            margin-top: 50px;
        }
        
    }
}
@media only screen and (max-width: 600px) {
    .ProjectPage_Container{
        margin-top: 20px;
        .Images_container{
           button{
               margin: 0 5px;
           }
        }
       
        
    }
}
@media only screen and (max-width: 310px) {
    .ProjectPage_Container{
        .Images_container{
           button{
               margin: 0;
           }
        }
       
        
    }
}