.ServiceCard_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba($color: #294B81, $alpha: .6) 0px 5px 15px;
    border-radius: 10px;
    margin: 20px;
    width: 300px;
    height: 250px;
    text-align: center;
    padding: 30px;
    img{
        width: 140px;
        height: 110px;
        object-fit: contain;
        object-position: 50%;
    }
  
    p{
        margin: 0;
        color: rgba($color: #000000, $alpha: .7);
    }
}
.Services_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;

    h4, h2{
        color: #294B81;
    }
    h2{
        margin-bottom: 50px !important;
    }
    .row2{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


}

@media only screen and (max-width: 412px) {
    .ServiceCard_Container{
        width: 90%;
        padding: 30px 10px;
    }
}