.Dashboard_Container{
    margin-top: 100px;
    min-height: 70vh;
    display: flex;
    .dashboard_Menu{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #f9f9f9  ;
        width: 250px;
        height: 70vh;
        padding: 50px 10px;
        a{
            color: black;
            font-size: 1.5rem;
            padding: 0;
            margin: 10px 0;
            cursor: pointer;
            font-weight: 500;
        }

        margin-top: -12px;
    }
    .c2{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 40px;
        a{
            border-bottom: 1px solid black;
            cursor: pointer;
        }
    }
}