.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px 0 50px 0;
    margin: 150px  auto;
    width: 300px;
    border: 1px solid rgba(0,0,0,0.1);
    button{
        border-radius: 0;
        width: 215px;
        margin-top: 10px;
    }
    .input{
        display: flex;
        width: 200px;
        flex-direction: column;
        input{
            width: 100%;
            margin: 10px 0;
            align-self: center;
        }
        label{
            margin: 0;
        }
    }
}