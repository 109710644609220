.EditProjectsPage_Container{
    background-color: rgba(220,220,220, .2);
    min-height: 80vh;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 30px 100px 30px;

   .row1{
       display: flex;
       justify-content: space-between;
       align-items: center;
   }
    select, input{
        padding: 5px;
        margin: 10px 10px 10px 0;
    }
    a{
        text-decoration: none;
        color: inherit;
        margin: 0;
        margin-left: 40px;
        color: white;
    }
    label{
        margin: 0 5px 0 10px;
    }
    .Project_Table{
        border: 1px solid black;
        border-radius: 5px;
        width: 80vw;
        .Project_Row:last-child{
            border: none
        }
        .Project_Row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            padding: 0 10px;
            border-bottom: 1px solid black;
            .row_button{
                color: white;
                border: none;
                cursor: pointer;
                padding: 5px 10px;
                margin: 0 5px;
                border-radius: 0;
            }
         
            .edit{
                background-color: green;
            }
            .title{
                max-width: 500px;
            }
            .keyproject{
                font-weight: 900;
            }

        }
    }
    img{
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}