.EditProject_Container{
    display: flex;
    align-items: flex-start;
    .ProjectPage_Container{
        flex-direction: column ;
        margin-top: 50px;
        .first_image{
            margin-bottom: 50px;
        }
        .description{
           margin-top: 20px;
        }
        .arrow{
            display: none;
        }
    }
    .editor {
        box-sizing: border-box;
        cursor: text;
        border-radius: 2px;
        background-color: white;
        z-index: 99999;
        border: 1px solid #DEDEDE;
        overflow-y: scroll;
        margin-top: 8px;
        padding: 0 5px;
        width: 457px;
        height: 250px !important;
        .border{
            border-right: 1px solid #D7D7D7;
            padding-right: 10px;
        }
        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        .toolbar{
            display: flex;
            border-bottom: 1px solid #DEDEDE;
            flex-direction: row !important;
            padding: 5px 0;
            img{
                height: auto;
                width: auto;
            }
            a{
                border: none;
                margin-left: auto;
                margin-right: 5px;
                cursor: pointer;
            }
    
        }
        .ProseMirror:focus{
            outline: none;
    
        }
        
    }
    
}

.EditProject_Container_Form{
    width: 30vw;
    margin-right: 100px;
    .images_container{
        display: flex;
        flex-direction: column;
        .images{
           display: flex;
           flex-wrap: wrap;
           max-width: 500px;
        }
        .image{
            height: 100px;
            width: 100px;
            margin: 5px 10px 5px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .deleteIcon{
                height: 20px;
                width: 20px;
                cursor: pointer;
                position: absolute;
            }
        }
    }
    .flex{
       display: flex;
       flex-wrap: wrap;
       margin-top: 10px;
        button{
            border-radius: 0;
            margin-right: 5px;
            font-weight: 600;
        }
    }
    .save{
       background-color: green;
    }
    .inputs{
       display: flex;
       width: 80%;
       flex-direction: column;
       margin: 5px 0;
       input{
           width: 450px;
           height: 20px;
       }
       .select{
        width: 470px;
       }
       textarea{
           width: 452px;
           height: 100px;
       }
       .textarea1{
        height: 50px;
       }
       label{
           margin-bottom: 4px;
           font-size: 16px;
       }
        select{
           width: 100px;
           font-size: 15px;
        }
        .file{
            height: auto;
        }
    }
}
