.RoadMapCard_Container{
    border-radius: 10px;
    height: 150px;
    background-color: rgba($color: white, $alpha: 1);
    background-size: 100% 100%;
    margin: 40px 20px;
    max-width: 70vw;
    width: 150px;
    margin-bottom: -24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: center;

    p{
        line-height: 1.6em !important;
        max-width: 150px;
        margin: 0;
    }
    h3{
        margin: 5px 0;
    }
}

// ----------------- Media Query ------------------- //


@media only screen and (max-width: 600px) {
    .RoadMapCard_Container{
        padding-left: 20px;
        padding-right: 20px;
        justify-content: center;
        margin: 0;

        .description{
            margin-left: 30px;
        }
        p{
            font-size: .8rem;
    
        }
    }
}
@media only screen and (max-width: 350px) {
    .RoadMapCard_Container{
        .description{
            margin-left: 30px;
        }
        p{
            font-size: .7rem;
    
        }
    }
}