@font-face {
  font-family: 'Bahnschrift';
  src: local('Bahnschrift Light SemiCondensed'), url(./assets/Bahnschrift/BAHNSCHRIFT1.TTF) format('truetype') ;
}
body {
  margin: 0;
  font-family: Bahnschrift;
  -webkit-font-smoothing: antialiased;
  background-color: white;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, .7);

}
html{
  scroll-behavior: smooth;
}

