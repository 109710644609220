.ContactUs_Container{
    display: flex;
    flex-direction: column;
    margin: 80px 0 150px 0;
    background-image: url(../../assets/images/wave.webp);
    background-color: #3B94D6;
    background-size: cover;
    min-height: 80vh;
    color: white;
    text-align: center;
    .sectionTitle{
        margin: 80px 0 50px 0;
        width: 100%;
        .title_description{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .9em;
            .icon{
                height: 20px;
                margin: 0 5px 0 15px;
            }
        }
    }
    .flex{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
    }
    a{
        text-decoration: none;
        color: inherit;
        margin-right: 5px;
    }
    .ContactForm_Container{
        display: flex;
        justify-content: center;
        .Firstcolumn{
            display: flex;
            flex-direction: column;
            width: 450px;
            margin: 0 20px;
            .Form{
                display: flex;
                flex-direction: column;   
                label,textarea, input{
                    margin: 10px 0;
                    padding: 10px;
                    border-radius: 20px;
                    border: 1px solid #E7E7E7;
                    background-color: white;
                    color: #A9A9A9;
                    cursor: pointer;
                    text-align: left;
                    font-size: 15px;

                }
                input::placeholder, textarea::placeholder {
                    color: #A9A9A9;

                }
                textarea{
                    height: 100px;
                    resize: none;
                }
                .inputfile {
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }
                .upload{
                    height: 20px;
                    margin-right: 10px;
                }
                label{
                    display: flex;
                    align-items: center;
                }
                
            }
            button{
            }
        }
    }
}

// ----------------- Media Query ------------------- //


@media only screen and (max-width: 950px) {
    .ContactUs_Container{
        h1{
            font-size: 2.5rem;
        }
        .ContactForm_Container{
            flex-direction: column;
            align-items: center;
            padding: 0 5vw;
            .Image{
                display: none;
            }
            .Firstcolumn{
                width: 100%;
            }
        }
    }

 
}
@media only screen and (max-width: 760px) {
   .ContactUs_Container{
  
   }
}
@media only screen and (max-width: 600px) {
   .ContactUs_Container{
       margin-top: 0;
    h1{
        font-size: 1.7rem;
    }
    p,a{
        font-size: .8rem;
        max-width: 90%;
    }
    .sectionTitle{
        align-self: center;
        .title_description{
            display: flex;
            align-items: flex-start;
           flex-direction: column;
        }
    }
   }
}

