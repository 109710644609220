.RoadMap_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
    .cards_container{
        margin: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
  
    p{
        width: 470px;

    }
    .desc{
        margin-left: 80px;
        margin-bottom: -80px;
    }
    h2{
        color: #294B81;
    }
}
// ----------------- Media Query ------------------- //

@media only screen and (max-width: 600px) {
    .RoadMap_Container{
        flex-direction: column;
        margin-bottom: 50px;
        .cards_container{
            margin: 0;
        }
        h2{
            margin-bottom: 100px;
        }
    }
    
}
@media only screen and (max-width: 500px) {
    .RoadMap_Container{
        padding: 0;
    }
    
}