.Dream_Container{
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    h2{
        font-size: 3rem;
        margin: 20px 0;
    }
    button{
        margin: 20px 0;
    }
}


  // ----------------- Media Query ------------------- //

  @media only screen and (max-width: 600px) {
    
    .Dream_Container{
        background-position: center;
        background-attachment:scroll ;
        h2{
            font-size: 1.5rem;
            margin: 0;
        }
    }
    
}