.VideoPlayer_Container{
    a{
        color: #39379B;
        background-color: white;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 2.4em;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .9;
    }
}