.NotFound_Container{
    background-image:  url('../../assets//images/404.webp');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    background-color: white;
    background-position: center;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    h1{
        margin: 0 20px;
    }
}